import Tracking from "./tracking";
import {documentReady} from './utils';

const Home = {
    initialize: () => {
        documentReady(() => {
            Tracking.pageDisplay('homepage', 'homepage');
        });
    }
}
Home.initialize();

export default Home;


