
const Tracking = {
    pageDisplay: (page, page_chapter1) => {
        window.tc_vars = {
            page: page,
            page_chapter1: page_chapter1
        };

        window.tC.container.reload({events:{pageDisplay:[{},{}]}});
    },
    page: (page, page_chapter1, step_name, step_number) => {
        step_name = step_name.replace(/&nbsp;/g, ' ');
        window.tc_vars = {
            page: page,
            page_chapter1: page_chapter1,
            step_name: step_name,
            step_number: step_number
        };

        window.tC.container.reload({events:{pageDisplay:[{},{}]}});
    },
    diagnostic: (page, page_chapter1, formule) => {
        window.tc_vars = {
            page: page,
            page_chapter1: page_chapter1,
            formule: formule
        };

        window.tC.container.reload({ events: { pageDisplay: [{}, {}] } });
    },
    clickExit: (click, click_position, q_and_a, goal_type) => {
        window.tc_vars = Object.assign({}, window.tc_vars, {
            click: click,
            click_position: click_position,
            q_and_a: q_and_a,
            goal_type: goal_type
        });

        window.tC.event.clickExit();
    }
}

export default Tracking;
